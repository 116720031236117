import store from '../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'promiseDispatcher',
  store,
})
class PromiseDispatcher extends VuexModule {
  public foregroundTaskCount = 0;

  @Action
  public async queueForegroundTask(call: () => Promise<unknown>): Promise<void> {
    this.context.commit('foregroundCountUp');

    try {
      await call();
    } finally {
      this.context.commit('foregroundCountDown');
    }
  }

  @Action
  public increase(): void {
    this.context.commit('foregroundCountUp');
  }
  @Action
  public decrease(): void {
    this.context.commit('foregroundCountDown');
  }

  @Mutation
  public foregroundCountUp() {
    this.foregroundTaskCount += 1;
  }

  @Mutation
  public foregroundCountDown() {
    this.foregroundTaskCount -= 1;
  }
}

export default getModule(PromiseDispatcher);
