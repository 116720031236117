
import _ from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { API } from '../api/service/APIAccess';
import Vue2Filters from 'vue2-filters';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { EmailTemplateType, getSelectableEmailTemplateType } from '@/api/model/EmailTemplateType';
import {
  EmailTemplate,
  getEmptyEmailTemplate as createEmptyEmailTemplate,
} from '@/api/model/EmailTemplate';
import VueHorizontal from 'vue-horizontal';
import { VueEditor } from 'vue2-editor';
import promiseDispatcher from '@/store/modules/promiseDispatcher';
import Messages from '@/store/modules/alert';

@Component({
  name: 'EmailTemplateView',
  components: {
    VueEditor,
    VueHorizontal,
    ConfirmDialog,
  },
  mixins: [Vue2Filters.mixin],
  filters: {},
})
export default class EmailTemplateView extends Vue {
  $refs!: {
    confirmDialog: ConfirmDialog;
  };

  @Prop(Boolean) readonly isAdult: boolean = false;

  public emailTypes = getSelectableEmailTemplateType();
  public selectedEmailType = EmailTemplateType.Registration;

  public selectedEmail: EmailTemplate = createEmptyEmailTemplate();
  public testemail = '';

  public emailTemplates: EmailTemplate[] = [];
  public search = '';
  public file?: File;

  public get headers(): unknown {
    return [
      { text: 'Name', align: 'start', value: 'templateName' },
      { text: 'Typ', align: 'start', value: 'niceType' },
      { text: 'Actions', value: 'action', sortable: false, align: 'right' },
    ];
  }

  public async mounted(): Promise<void> {
    await promiseDispatcher.queueForegroundTask(async () => {
      this.emailTemplates = await API.getEmailTemplates(this.isAdult);
    });
  }

  private rowClicked(item: EmailTemplate, row: any): void {
    row.select(true);
    console.log(item.templateName);
    this.selectedEmail = item;
  }

  private async uploadFile(): Promise<void> {
    if (!this.file) return;

    await promiseDispatcher.queueForegroundTask(async () => {
      if (await API.uploadEmailAttachement(this.selectedEmail.id, this.file!))
        this.selectedEmail.attachements.push(this.file!.name);
    });
  }

  private async sendTestEmail(): Promise<void> {
    if (!this.testemail || !this.selectedEmail.id) return;

    await promiseDispatcher.queueForegroundTask(async () => {
      API.sendTestEmail({
        templateId: this.selectedEmail.id,
        templateType: this.selectedEmail.emailTemplateType,
        to: this.testemail,
      });
    });
  }

  private async showModel(): Promise<void> {
    if (!this.selectedEmail.id) return;

    const result = await API.getEmailTemplateData(this.selectedEmail.emailTemplateType);
    Messages.showInfo(result.data);
  }

  private async deleteAttachement(name: string): Promise<void> {
    if (!this.selectedEmail) return;

    await promiseDispatcher.queueForegroundTask(async () => {
      if (await API.deleteEmailTemplateAttachement(this.selectedEmail.id, name))
        this.selectedEmail.attachements = this.selectedEmail.attachements.filter(x => x !== name);
    });
  }

  private async deleteEmailTemplate(email: EmailTemplate): Promise<void> {
    await promiseDispatcher.queueForegroundTask(async () => {
      await API.deleteEmailTemplate(email.id);

      this.emailTemplates = this.emailTemplates.filter(x => x !== email);
      if (this.selectedEmail == email) {
        this.selectedEmail = createEmptyEmailTemplate();
      }
    });
  }
  private async newEmailTemplate(email: EmailTemplate): Promise<void> {
    await promiseDispatcher.queueForegroundTask(async () => {
      await API.addEmailTemplate({
        emailTemplateType: EmailTemplateType.Registration,
        templateName: 'Neue Email',
        isAdult: this.isAdult,
        subject: '',
        templateData: '',
      });

      this.emailTemplates = await API.getEmailTemplates(this.isAdult);
    });
  }

  private async saveTemplate(): Promise<void> {
    if (this.selectedEmail === undefined) return;

    await promiseDispatcher.queueForegroundTask(async () => {
      await API.updateEmailTemplate({
        id: this.selectedEmail!.id,
        emailTemplateType: this.selectedEmail!.emailTemplateType,
        templateName: this.selectedEmail!.templateName,
        subject: this.selectedEmail!.subject,
        templateData: this.selectedEmail!.templateData,
      });
    });
  }
}
