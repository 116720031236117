
  import _ from 'lodash';
  import { Component, Vue } from 'vue-property-decorator';
  import { API } from '../api/service/APIAccess';
  import Vue2Filters from 'vue2-filters';
  import promiseDispatcher from '@/store/modules/promiseDispatcher';
  import ConfirmDialog from '@/components/ConfirmDialog.vue';
  import { Coupon } from '@/api/model/Coupon';
  import Messages from '@/store/modules/alert'; 
  import moment from 'moment';

  @Component({
    name: 'CouponsView',
    components: {
      ConfirmDialog
    },
    mixins: [Vue2Filters.mixin],
    filters: {},
  })
  export default class CouponsView extends Vue {
    $refs!: {
      confirmDialog: ConfirmDialog;
    };
  
    public search = '';
    public coupons: Coupon[] = [];
    public newCoupon: Coupon = {
        id: '',
      name: '',
      from: '',
      to: '',
      discount: 0,
      code: '',
      maxUses: 0,
      used: 0,
      isActive: false,
    };

    public showAddCoupon = false;
    public selectFromOpen = false;
    public selectToOpen = false;
    public fromValue = '';
    public toValue = '';
  
    public get headers(): unknown {
      return [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Start', align: 'start', value: 'from' },
        { text: 'Ende', align: 'start', value: 'to' },
        { text: 'Rabatt', align: 'start', value: 'discount' },
        { text: 'Coupon code', align: 'start', value: 'code' },
        { text: 'Buchungslimit', align: 'start', value: 'maxUses' },
        { text: 'Buchungen', align: 'start', value: 'used' },
        { text: 'Ist aktiv', align: 'start', value: 'isActive' },
        { text: 'Aktionen', align: 'left', value: 'actions', sortable: false },
      ];
    }

    private validation(): boolean {
        return this.newCoupon.from && this.newCoupon.to && moment(this.newCoupon.from).isBefore(this.newCoupon.to) || !this.newCoupon.from || !this.newCoupon.to;
    }
  
    private addCoupon(): void {
        this.newCoupon = {
            id: '',
            name: '',
            from: '',
            to: '',
            discount: 0,
            code: '',
            maxUses: 0,
            used: 0,
            isActive: false,
        };
        this.fromValue = '';
        this.toValue = '';

        this.showAddCoupon = true;
    }
    private editCoupon(item: Coupon): void {
      this.newCoupon = _.cloneDeep(item);
      this.fromValue = moment(this.newCoupon.from).format('DD.MM.YYYY');
        this.toValue = moment(this.newCoupon.to).format('DD.MM.YYYY');
        this.showAddCoupon = true;
    }

    private onChangeDate(): void {
        this.fromValue = this.newCoupon.from ? moment(this.newCoupon.from).format('DD.MM.YYYY') : '';
        this.toValue = this.newCoupon.to ? moment(this.newCoupon.to).format('DD.MM.YYYY') : '';
    }

    private discountChange(): void {
        if (this.newCoupon.discount > 100) {
            this.newCoupon.discount = 100;
        }
        if (this.newCoupon.discount < 0) {
            this.newCoupon.discount = 0;
        }
    }

    private async saveCoupon(coupon: Coupon): Promise<void> {
        try {
            if(!this.validation()) {
                Messages.showError('Startdatum muss vor dem Enddatum liegen');
                return;
            }
            if (coupon.id) {
                await API.updateCoupon(coupon);
            } else {
                await API.createCoupon(coupon);
            }
          this.showAddCoupon = false;
          this.loadAllCoupons();
        } catch (e) {
          Messages.showError(e);
        }
    }

    private deleteCoupon(item: Coupon): void {
        this.$refs.confirmDialog.show('Möchten Sie diesen Gutschein wirklich löschen?')
          .then((res) => {
            if(res) this.deleteCouponInternal(item)
          });
    }

    private async deleteCouponInternal(item: Coupon) {
        try {
          await API.deleteCoupon(item.id);
          this.coupons = this.coupons.filter((c) => c.id !== item.id);
        } catch (e) {
          Messages.showError(e);
        }
    }
  
    private async mounted(): Promise<void> {
      await this.loadAllCoupons();
    }
  
    private async loadAllCoupons(): Promise<void> {
      await promiseDispatcher.queueForegroundTask(this.loadAllCouponsInternal);
    }
  
    private async loadAllCouponsInternal(): Promise<void> {
      this.coupons = await API.getAllCoupons();
    }

    private async updateActiveState(coupon: Coupon): Promise<void> {
      try {
        const oldCoupon = coupon;
        const result = await API.changeActiveCoupon(coupon);
  
        Object.assign(coupon, result);
        coupon = oldCoupon;
      } catch (e) {
        Messages.showError(e);
  
        const current = await API.getCoupon(coupon.id);
        Object.assign(coupon, current);
      }
    }
}
  