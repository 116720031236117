import store from '../index';
import { userService } from '@/api/service/UserService';
import router from '../../router';
import alertModule from './alert';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { User } from '../../api/model/User';
import alert from './alert';

const initialUser = userService.getCurrentUser();

@Module({
  dynamic: true,
  namespaced: true,
  name: 'authModule',
  store,
})
class AuthModule extends VuexModule {
  public loggedIn: boolean = initialUser != null;
  public loggingIn = false;
  public user: User | null = initialUser;

  @Action
  public async login({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<void> {
    this.context.commit('loginRequest', username);

    try {
      const user = await userService.login(username, password);
      this.context.commit('loginSuccess', user);
    } catch (error) {
      this.context.commit('loginFailure', error);
      alert.showError(error as string);
      return;
    }

    router.push('/');
  }

  @Action
  public async renewToken(): Promise<boolean> {
    this.context.commit('renewTokenRequested');

    try {
      const user = await userService.renewToken();
      this.context.commit('loginSuccess', user);
      return true;
    } catch (e) {
      this.context.commit('loginFailure');
      alertModule.showError(e as string);
      return false;
    }
  }

  @Action
  public logout(): void {
    userService.logout();
    this.context.commit('logoutComplete');
  }

  @Mutation
  public renewTokenRequested(): void {
    this.loggingIn = true;
  }

  @Mutation
  public loginRequest(user: User): void {
    this.loggingIn = true;
    this.user = user;
  }

  @Mutation
  public loginSuccess(user: User): void {
    this.loggedIn = true;
    this.user = user;
  }

  @Mutation
  public loginFailure(): void {
    this.loggingIn = false;
    this.loggedIn = false;
    this.user = null;
  }

  @Mutation
  public logoutComplete(): void {
    this.loggingIn = false;
    this.loggedIn = false;
    this.user = null;
  }
}

export default getModule(AuthModule);
