import moment from 'moment';

export class DateHelper {
  public static onlyDate(date: Date): Date {
    const result = moment(date).startOf('day').toDate();

    return result;
  }

  public static addDays(date: Date, days: number): Date {
    const start = moment(date).startOf('day');
    const end = start.add(days, 'days');
    return end.toDate();
  }
}
