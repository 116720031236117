import _ from 'lodash';
import { JsonSerializer } from './JsonSerializer';
import globalConfig from '../../globalconfiguration';
import Messages from '@/store/modules/alert';
import { userService } from './UserService';
import authModule from '@/store/modules/auth';
import { PresentableCourse } from '../model/PresentableCourse';
import { Page } from '../model/Page';
import { EmailTemplate } from '../model/EmailTemplate';
import { Coupon } from '../model/Coupon';
import { CourseSetting } from '../model/CourseSetting';
import { EmailTemplateName } from '../model/EmailTemplateName';
import { EmailTemplateType } from '../model/EmailTemplateType';
import { EmailTemplateData } from '../model/EmailTemplateData';

class APIAccess {

  public async getAllCoupons(): Promise<Coupon[]> {
    return this.GetAllFromPage<Coupon>('/courseregistration/api/v1/coupons/page');
  }

  public async getCoupon(id: string): Promise<Coupon> {
    return this.SingleGet<Coupon>(
      '/courseregistration/api/v1/coupons',
      (url: URL) => {
        url.searchParams.append('id', `${id}`);
      }
    );
  }

  public async changeActiveCoupon(coupon: Coupon): Promise<Coupon> {
    return this.genericCallWithResult(
      {
        isActive: coupon.isActive
      },
      'PUT',
      '/courseregistration/api/v1/coupons/' + coupon.id + '/active'
    );
  }

  public async createCoupon(data: any): Promise<Coupon> {
    return this.genericCallWithResult(data, 'POST', '/courseregistration/api/v1/coupons');
  }

  public async deleteCoupon(id: string): Promise<boolean> {
    return this.genericCall({}, 'DELETE', '/courseregistration/api/v1/coupons/' + id);
  }

  public async updateCoupon(coupon: Coupon): Promise<Coupon> {
    return this.genericCallWithResult(
      {
        name: coupon.name,
        from: coupon.from,
        to: coupon.to,
        discount: coupon.discount,
        code: coupon.code,
        maxUses: coupon.maxUses
      },
      'PUT',
      '/courseregistration/api/v1/coupons/' + coupon.id
    );
  }

  public async getAllCourseSettings(): Promise<CourseSetting[]> {
    return this.GetAllFromPage<CourseSetting>('/courseregistration/api/v1/coursesettings/page');
  }

  public async getCourseSettings(id: string): Promise<CourseSetting> {
    return this.SingleGet<CourseSetting>(
      '/courseregistration/api/v1/coursesettings',
      (url: URL) => {
        url.searchParams.append('id', `${id}`);
      }
    );
  }

  public async updateCourseSetting(setting: CourseSetting): Promise<CourseSetting> {
    return this.genericCallWithResult(
      {
        id: setting.id,
        isActive: setting.isActive,
        isIntensive: setting.isIntensive,
        monthToPlanAhead: setting.monthToPlanAhead,
        registrationDate: setting.registrationDate,
        useRegistrationDate: setting.useRegistrationDate,
        unitCount: setting.unitCount,
        skippableUnitCount: setting.skippableUnitCount,
        price: setting.price,
        registrationEmail: setting.registrationEmail,
        registrationDays: setting.registrationDays,
      },
      'PUT',
      '/courseregistration/api/v1/coursesettings'
    );
  }

  public async sendTestEmail(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/courseregistration/api/v1/emailTemplate/Testmail');
  }

  public async syncCourseSettings(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/courseregistration/api/v1/coursesettings/sync');
  }

  public async updateCourses(data: any): Promise<boolean> {
    return this.genericCallWithResult(data, 'PUT', '/courseregistration/api/v1/course');
  }

  public async activateCourses(data: any): Promise<PresentableCourse> {
    return this.genericCallWithResult(data, 'PUT', '/courseregistration/api/v1/course/active');
  }

  public async addEmailTemplate(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/courseregistration/api/v1/emailTemplate');
  }

  public async updateEmailTemplate(data: any): Promise<boolean> {
    console.log(data);
    return this.genericCall(data, 'PUT', '/courseregistration/api/v1/emailTemplate');
  }
  public async deleteEmailTemplate(id: string): Promise<boolean> {
    return this.genericCall({ id }, 'DELETE', '/courseregistration/api/v1/emailTemplate');
  }
  public async getEmailTemplates(isAdult: boolean): Promise<EmailTemplate[]> {
    return this.GetAllFromPage<EmailTemplate>('/courseregistration/api/v1/emailTemplate', url =>
      url.searchParams.append('isAdult', `${isAdult}`)
    );
  }
  public async getEmailTemplateNames(isAdult: boolean): Promise<EmailTemplateName[]> {
    return this.GetAllFromPage<EmailTemplateName>(
      '/courseregistration/api/v1/emailTemplate/name',
      url => url.searchParams.append('isAdult', `${isAdult}`)
    );
  }

  public async deleteEmailTemplateAttachement(emailId: string, name: string): Promise<boolean> {
    return this.genericCall(
      {
        id: emailId,
        name,
      },
      'DELETE',
      '/courseregistration/api/v1/emailTemplate/attachment'
    );
  }

  public async getEmailTemplateData(type: EmailTemplateType): Promise<EmailTemplateData> {
    return this.SingleGet<EmailTemplateData>(
      '/courseregistration/api/v1/emailTemplate/TemplateData',
      (url: URL) => {
        url.searchParams.append('type', `${type}`);
      }
    );
  }

  public async uploadEmailAttachement(emailId: string, file: File): Promise<boolean> {
    const call = (): Promise<Response> => {
      const fullUrl = new URL(
        '/courseregistration/api/v1/emailTemplate/attachment',
        globalConfig.APIUrl
      );

      const formData = new FormData();
      formData.append('file', file);

      return fetch(fullUrl.toString(), {
        method: 'POST',
        headers: {
          ...userService.getAuthHeader(),
          'X-Name': file.name,
          'X-EmailId': emailId,
        },
        body: formData,
      });
    };

    const promise = await this.processApiCall(call);

    if (promise.status === 200) return true;

    Messages.showError(await promise.text());
    return false;
  }

  public async getPresentableCourses(
    startDate: string,
    endDate: string
  ): Promise<PresentableCourse[]> {
    return this.GetAllFromPage<PresentableCourse>(
      '/courseregistration/api/v1/course/page',
      (url: URL) => {
        url.searchParams.append('startDate', `${startDate}`);
        url.searchParams.append('endDate', `${endDate}`);
      }
    );
  }

  private GetAllFromPage<TResult>(
    apiPath: string,
    addParameters?: (url: URL) => void | undefined
  ): Promise<TResult[]> {
    const call = (p: number): Promise<Response> => {
      const url = new URL(apiPath, globalConfig.APIUrl);
      url.searchParams.append('page', `${p}`);
      url.searchParams.append('size', '50');

      if (addParameters !== undefined) addParameters(url);

      return fetch(url.toString(), {
        method: 'GET',
        headers: userService.getAuthHeader(),
      });
    };

    return this.GetAllFromPageFromCall<TResult>(call);
  }
  private async SingleGet<TResult>(
    apiPath: string,
    addParameters?: (url: URL) => void | undefined
  ): Promise<TResult> {
    const call = (): Promise<Response> => {
      const url = new URL(apiPath, globalConfig.APIUrl);

      if (addParameters !== undefined) addParameters(url);

      return fetch(url.toString(), {
        method: 'GET',
        headers: userService.getAuthHeader(),
      });
    };

    const result = await this.processApiCall(call);
    if (!result.ok) throw new Error(await result.text());

    const jsonData = JsonSerializer.Deserialize(await result.text());
    return jsonData as TResult;
  }

  private async GetAllFromPageFromCall<TResult>(
    call: (p: number) => Promise<Response>
  ): Promise<TResult[]> {
    let entities: TResult[] = [];

    let page = 0;
    let pageContainer: Page<TResult>;
    do {
      const result = await this.processApiCall(() => call(page++));
      if (!result.ok) throw new Error(await result.text());

      const jsonData = JsonSerializer.Deserialize(await result.text());
      pageContainer = jsonData as Page<TResult>;
      if (pageContainer && pageContainer.entities)
        entities = entities.concat(pageContainer.entities);
    } while (pageContainer.entities.length === pageContainer.size);

    return entities;
  }

  private async processApiCall(call: () => Promise<Response>): Promise<Response> {
    const retry = 2;

    for (let i = 0; i < retry; i++) {
      const promise = await call();

      if (promise.status === 401 && (await authModule.renewToken())) continue;

      return promise;
    }

    throw new Error('Api Call failed');
  }

  private async genericCallWithResult<TResult>(
    data: any,
    type: string,
    url: string
  ): Promise<TResult> {
    const call = (): Promise<Response> => {
      const fullUrl = new URL(url, globalConfig.APIUrl);

      return fetch(fullUrl.toString(), {
        method: type,
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const result = await this.processApiCall(call);
    if (!result.ok) throw new Error(await result.text());

    const jsonData = JsonSerializer.DeserializeAppointment(await result.text());
    return jsonData as TResult;
  }

  private async genericCall(data: any, type: string, url: string): Promise<boolean> {
    const call = (): Promise<Response> => {
      const fullUrl = new URL(url, globalConfig.APIUrl);

      return fetch(fullUrl.toString(), {
        method: type,
        headers: {
          ...userService.getAuthHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await this.processApiCall(call);

    if (promise.status === 200) return true;

    Messages.showError(await promise.text());
    return false;
  }
}

export const API = new APIAccess();
