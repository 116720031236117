class GlobalConfig {
  public readonly IssuerUrl: string = 'https://cashman1972.de';
  public readonly APIUrl: string = 'https://api.cashman1972.de';

  // Local Dev
 //public readonly APIUrl: string = 'http://localhost:5212';

  public readonly Version: string = '1.3';
}

export default new GlobalConfig();
