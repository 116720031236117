
import { Component, Vue } from 'vue-property-decorator';
import auth from '../store/modules/auth';

@Component({
  name: 'LoginView',
  components: {},
})
export default class LoginView extends Vue {
  private username = '';
  private password = '';
  private showPassword = false;

  private get loggingIn(): boolean {
    return auth.loggingIn;
  }

  private async login(): Promise<void> {
    const loginData = { username: this.username, password: this.password };

    await auth.login(loginData);
  }
}
