import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import BookableCoursesView from '../views/BookableCoursesView.vue';
import CouponsView from '../views/CouponsView.vue';
import EmailTemplateView from '../views/EmailTemplateView.vue';
import CourseSettingsView from '../views/CourseSettingsView.vue';
import LoginView from '../views/LoginView.vue';
import auth from '@/store/modules/auth';

const publicPages = ['/login'];

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'bookings',
    component: BookableCoursesView,
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: CouponsView,
  },
  {
    path: '/emailchildtemplates',
    name: 'emailchildtemplates',
    component: EmailTemplateView,
    props: { isAdult: false },
  },
  {
    path: '/emailadulttemplates',
    name: 'emailadulttemplates',
    component: EmailTemplateView,
    props: { isAdult: true },
  },
  {
    path: '/coursesettings',
    name: 'coursesettings',
    component: CourseSettingsView,
  },
  {
    path: '/login',
    name: 'login-view',
    component: LoginView,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = !publicPages.includes(to.path);
  if (authRequired && !auth.loggedIn) return next('/login');

  next();
});

export default router;
