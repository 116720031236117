
import _, { forEach } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { API } from '../api/service/APIAccess';
import Vue2Filters from 'vue2-filters';
import promiseDispatcher from '@/store/modules/promiseDispatcher';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { CourseSetting } from '@/api/model/CourseSetting';
import { EmailTemplateName } from '@/api/model/EmailTemplateName';
import moment from 'moment';
import Messages from '@/store/modules/alert';
import ChangeMultipleCourseSettingsDialog from '@/components/ChangeMultipleCourseSettingsDialog.vue';
import { set } from 'vue';
import { CourseType } from '@/api/model/CourseType';

@Component({
  name: 'CourseSettingsView',
  components: {
    ConfirmDialog,
    ChangeMultipleCourseSettingsDialog,
  },
  mixins: [Vue2Filters.mixin],
  filters: {},
})
export default class CourseSettingsView extends Vue {
  currency(arg0: string, arg1: number, arg2: { decimalSeparator: string }) {
    throw new Error('Method not implemented.');
  }
  $refs!: {
    confirmDialog: ConfirmDialog;
    changeMultipleCourseSettingsDialog: ChangeMultipleCourseSettingsDialog;
  };

  public selectMonthOpen = false;
  public monthToShow = '';
  public activateMonthSelector = null;

  public selectedRegistartionDays: number[] = [];

  public search = '';
  public settings: CourseSetting[] = [];
  public childEmails: EmailTemplateName[] = [];
  public adultEmails: EmailTemplateName[] = [];

  public adultEmailIds: string[] = [];
  public childEmailIds: string[] = [];

  public saveUseRegistrationDate = false;
  public saveRegistrationDate = '';
  public saveMonthToPlanAhead = 0;

  public get headers(): unknown {
    return [
      { text: 'Name', align: 'start', value: 'name' },
      { text: 'Typ', align: 'start', value: 'courseType' },
      { text: 'Wochentage', align: 'start', value: 'occurancePerWeek' },
      { text: 'Datum', align: 'start', value: 'range.startDate' },
      { text: 'Uhrzeit', align: 'start', value: 'time.startTime' },

      { text: 'Einheiten', align: 'start', value: 'unitCount' },
      { text: 'Kranktage', align: 'start', value: 'skippableUnitCount' },
      { text: 'Preis', align: 'start', value: 'price' },

      { text: 'Anmelde Email', value: 'registrationEmail' },
      { text: 'Intensiv', value: 'isIntensive' },
      { text: 'Monate Planung', value: 'monthToPlanAhead' },
      { text: 'Freigeschaltet', value: 'isActive' },
    ];
  }

  private getEmailIds(courseType: CourseType) {
    return courseType === CourseType.Adult ? this.adultEmailIds : this.childEmailIds;
  }

  private getEmailTemplateName(id: string): string {
    let templateName = this.adultEmails.find(x => x.id == id);
    if (!templateName) templateName = this.childEmails.find(x => x.id == id);
    if (!templateName) return 'Nicht definiert.';
    return templateName.templateName;
  }

  private isRegistrationPossible(setting: CourseSetting, day: string): string {
    return setting.registrationDays.includes(day)
      ? 'RegistrationPossible'
      : 'RegistrationNotPossible';
  }

  private prepareRegistartionDays(setting: CourseSetting): void {
    this.selectedRegistartionDays = [];

    for (let i = 0; i < setting.occurancePerWeek.length; i++)
      if (setting.registrationDays.includes(setting.occurancePerWeek[i]))
        this.selectedRegistartionDays.push(i);
  }

  private updateRegistrationDays(setting: CourseSetting): Promise<void> {
    const newDays: string[] = [];
    for (let i of this.selectedRegistartionDays) newDays.push(setting.occurancePerWeek[i]);

    setting.registrationDays = newDays;

    return this.updateSetting(setting);
  }

  private async mounted(): Promise<void> {
    await this.loadAllSettings();
  }

  private async loadAllSettings(): Promise<void> {
    await promiseDispatcher.queueForegroundTask(this.loadAllSettingsInternal);
  }

  private async loadAllSettingsInternal(): Promise<void> {
    this.settings = await API.getAllCourseSettings();
    this.adultEmails = await API.getEmailTemplateNames(true);
    this.childEmails = await API.getEmailTemplateNames(false);

    this.childEmailIds = _(this.childEmails)
      .map(x => x.id)
      .value();

    this.adultEmailIds = _(this.adultEmails)
      .map(x => x.id)
      .value();
  }

  private async updateSetting(setting: CourseSetting): Promise<void> {
    try {
      console.log(setting.useRegistrationDate);
      console.log(setting.registrationDate);

      const oldTime = setting.time;
      const result = await API.updateCourseSetting(setting);

      Object.assign(setting, result);
      setting.time = oldTime;
    } catch (e) {
      Messages.showError(e);

      const current = await API.getCourseSettings(setting.id);
      Object.assign(setting, current);
    }
  }

  private async storeChanges(setting: CourseSetting): Promise<void> {
    this.saveUseRegistrationDate = setting.useRegistrationDate;
    this.saveRegistrationDate = setting.registrationDate;
    this.saveMonthToPlanAhead = setting.monthToPlanAhead;
  }

  private async undoChanges(setting: CourseSetting): Promise<void> {
    setting.useRegistrationDate = this.saveUseRegistrationDate;
    setting.registrationDate = this.saveRegistrationDate;
    setting.monthToPlanAhead = this.saveMonthToPlanAhead;
  }

  private async multiEditCourses(): Promise<void> {
    const result = await this.$refs.changeMultipleCourseSettingsDialog.show();
    if (!result) return;

    const input = {
      selectedIntensive: this.$refs.changeMultipleCourseSettingsDialog.getSelectedIntensives(),
      selectedCouryeTypes: this.$refs.changeMultipleCourseSettingsDialog.getSelectedCourseTypes(),
      price: this.$refs.changeMultipleCourseSettingsDialog.price,
      unitCount: this.$refs.changeMultipleCourseSettingsDialog.unitCount,
      skippableUnitCount: this.$refs.changeMultipleCourseSettingsDialog.skippableUnitCount,
    };

    for (const setting of this.settings) {
      if (!input.selectedIntensive.includes(setting.isIntensive)) continue;
      if (!input.selectedCouryeTypes.includes(setting.courseType)) continue;

      setting.price = input.price;
      setting.unitCount = input.unitCount;
      setting.skippableUnitCount = input.skippableUnitCount;

      await this.updateSetting(setting);
    }
  }

  private async syncCourses(): Promise<void> {
    await promiseDispatcher.queueForegroundTask(this.syncCoursesInternal);
  }

  private async syncCoursesInternal(): Promise<void> {
    const first = moment(Date.now()).format('YYYY-MM') + '-01';
    const last = moment(Date.parse(first)).add(6, 'M').endOf('month');

    await API.syncCourseSettings({
      startDate: first,
      endDate: last.format('YYYY-MM-DD'),
    });

    await this.loadAllSettingsInternal();
  }
}
