import { EmailTemplateType } from './EmailTemplateType';

export interface EmailTemplate {
  id: string;
  emailTemplateType: EmailTemplateType;

  templateName: string;
  subject: string;
  templateData: string;
  attachements: string[];
}

export function getEmptyEmailTemplate(): EmailTemplate {
  return {
    attachements: [],
    id: '',
    emailTemplateType: EmailTemplateType.Registration,
    subject: '',
    templateData: '',
    templateName: '',
  };
}
