
import { Component, Vue } from 'vue-property-decorator';
import LoadingDialog from './components/LoadingDialog.vue';
import StatusNotification from './components/StatusNotification.vue';
import globalConfig from './globalconfiguration';

@Component({
  name: 'App',
  components: {
    LoadingDialog,
    StatusNotification,
  },
})
export default class App extends Vue {
  private version: string = globalConfig.Version;
  private showDrawer = false;
  private menuItems = [
    { title: 'Buchbare Kurse', link: '/', icon: 'mdi-calendar-check' },
    { title: 'Gutscheine', link: '/coupons', icon: 'mdi-ticket-percent-outline' },
    { title: 'Email Kinderkurse', link: '/emailchildtemplates', icon: 'mdi-mail' },
    { title: 'Email Erwachsenenkurse', link: '/emailadulttemplates', icon: 'mdi-mail' },
    { title: 'Kurs Einstellungen', link: '/coursesettings', icon: 'mdi-cog' },
  ];
}
