
import { Component, Vue } from 'vue-property-decorator';
import Messages from '@/store/modules/alert';
import { CourseType } from '@/api/model/CourseType';

@Component({
  name: 'ChangeMultipleCourseSettingsDialog',
})
export default class ChangeMultipleCourseSettingsDialog extends Vue {
  $refs!: {
    form: any;
  };

  public valid = true;
  public showDialog = false;

  public courseTypes = [
    'Seepferdchen',
    'Bronze',
    'Silber',
    'Gold',
    'Totenkopf 1h',
    'Totenkopf 1,5h',
    'Totenkopf 2h',
  ];
  public intensiveTyps = ['Normal', 'Intensiv'];

  public selectedCourseTypes: number[] = [];
  public selectedintensiveTypes: number[] = [];

  public unitCount = 0;
  public skippableUnitCount = 0;
  public price = 0;

  private showDialogPromiseResolve?: (value: boolean | PromiseLike<boolean>) => void;

  public getSelectedIntensives(): boolean[] {
    const result: boolean[] = [];

    if (this.selectedintensiveTypes.includes(0)) result.push(false);
    if (this.selectedintensiveTypes.includes(1)) result.push(true);

    return result;
  }

  public getSelectedCourseTypes(): string[] {
    const result: string[] = [];

    if (this.selectedCourseTypes.includes(0)) result.push(CourseType.Seahorse);
    if (this.selectedCourseTypes.includes(1)) result.push(CourseType.Bronze);
    if (this.selectedCourseTypes.includes(2)) result.push(CourseType.Silver);
    if (this.selectedCourseTypes.includes(3)) result.push(CourseType.Gold);
    if (this.selectedCourseTypes.includes(4)) result.push(CourseType.Skull1);
    if (this.selectedCourseTypes.includes(5)) result.push(CourseType.Skull1Dot5);
    if (this.selectedCourseTypes.includes(6)) result.push(CourseType.Skull2);

    return result;
  }

  public async show(): Promise<boolean> {
    if (this.$refs.form !== undefined) await this.$refs.form.resetValidation();

    this.showDialog = true;
    return new Promise(resolve => {
      this.showDialogPromiseResolve = resolve;
    });
  }

  private async saveAndClose(): Promise<void> {
    await this.$refs.form.validate();

    if (!this.valid) {
      Messages.showError('Fülle alle Felder richtig aus!');
      return;
    }

    this.showDialog = false;
    this.showDialogPromiseResolve(true);
  }

  private close(): void {
    if (this.showDialogPromiseResolve === null || this.showDialogPromiseResolve === undefined)
      return;

    this.showDialog = false;
    this.showDialogPromiseResolve(false);
  }
}
