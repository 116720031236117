import { render, staticRenderFns } from "./EmailTemplateView.vue?vue&type=template&id=4c46bb6b&scoped=true"
import script from "./EmailTemplateView.vue?vue&type=script&lang=ts"
export * from "./EmailTemplateView.vue?vue&type=script&lang=ts"
import style0 from "./EmailTemplateView.vue?vue&type=style&index=0&id=4c46bb6b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c46bb6b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCol,VDataTable,VEditDialog,VFileInput,VIcon,VRow,VSelect,VSpacer,VTextField,VToolbar})
