export enum CourseType {
  Seahorse = 'Seahorse',
  Bronze = 'Bronze',
  Toddler = 'Toddler', // LEGACY
  Adult = 'Adult',
  Silver = 'Silver',
  Gold = 'Gold',
  Skull1 = 'Skull1',
  Skull1Dot5 = 'Skull1Dot5',
  Skull2 = 'Skull2',
  Intensive = 'Intensive',
}

const CourseTypeName = new Map<CourseType, string>([
  [CourseType.Seahorse, 'Seepferdchen'],
  [CourseType.Bronze, 'Bronze'],
  [CourseType.Silver, 'Silber'],
  [CourseType.Gold, 'Gold'],
  [CourseType.Skull1, 'Totenkopf 1h'],
  [CourseType.Skull1Dot5, 'Totenkopf 1.5h'],
  [CourseType.Skull2, 'Totenkopf 2h'],
  [CourseType.Intensive, 'Intensiv'],

  [CourseType.Adult, 'Erwachsen'],
]);

export function getCourseTypeString(e: CourseType): string {
  let name = CourseTypeName.get(e);
  if (!name) name = CourseTypeName.get(CourseType[e]);
  if (!name) return 'Nicht definiert.';

  return name;
}
