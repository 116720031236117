
import { Component, Vue } from 'vue-property-decorator';
import alertModule from '../store/modules/alert';

@Component({
  name: 'StatusNotification',
})
export default class StatusNotification extends Vue {
  private get alert() {
    return alertModule;
  }
  private get showError(): boolean {
    return alertModule.showErrorMessage;
  }

  private set showError(value: boolean) {
    alertModule.clearMessage();
  }

  private get showInfo(): boolean {
    return alertModule.showInfoMessage;
  }

  private set showInfo(value: boolean) {
    alertModule.clearMessage();
  }

  private get showNotification(): boolean {
    return alertModule.showNotification;
  }

  private set showNotification(value: boolean) {
    alertModule.clearMessage();
  }
}
