import _, { keyBy } from 'lodash';

export enum EmailTemplateType {
  Registration = 'Registration',
  Renewal = 'Renewal',
}

const EmailTemplateTypeName = new Map<EmailTemplateType, string>([
  [EmailTemplateType.Registration, 'Erstanmeldung'],
  [EmailTemplateType.Renewal, 'Erneuerung'],
]);

export function getEmailTemplateNameString(e: EmailTemplateType): string {
  let name = EmailTemplateTypeName.get(e);
  if (!name) name = EmailTemplateTypeName.get(EmailTemplateType[e]);
  if (!name) return 'Nicht definiert.';

  return name;
}

export function getSelectableEmailTemplateType(): EmailTemplateType[] {
  return [EmailTemplateType.Registration];
}
