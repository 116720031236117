import moment from 'moment';
import { DateHelper } from './DateHelper';

export class JsonSerializer {
  public static DeserializeAppointment(data: string): unknown {
    return JSON.parse(data, JsonSerializer.AppointmentDateTime);
  }

  public static Deserialize(data: string): unknown {
    return JSON.parse(data, JsonSerializer.ReviveDateTime);
  }

  private static ReviveDateTime(key: string, value: unknown): unknown {
    const low = key.toLocaleLowerCase();

    try {
      switch (low) {
        case 'date':
        case 'birthday':
        case 'announcementdate':
        case 'paiddate':
        case 'startdate':
        case 'enddate':
        case 'validfrom':
        case 'validto':
        case 'sessiondate':
        case 'submitdate':
        case 'handedout':
          return DateHelper.onlyDate(new Date(value as string));

        case 'starttime':
        case 'endtime':
          return moment.duration(value as string);

        case 'startdatetime':
        case 'enddatetime':
          return new Date(value as string);

        default:
          return value;
      }
    } catch {
      return value;
    }
  }

  private static AppointmentDateTime(key: string, value: unknown): unknown {
    const low = key.toLocaleLowerCase();

    try {
      switch (low) {
        case 'start':
        case 'end':
          return new Date(value as string);

        default:
          return value;
      }
    } catch {
      return value;
    }
  }
}
