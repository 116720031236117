import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment, { Duration } from 'moment';
import 'moment-duration-format';

Vue.config.productionTip = false;

Vue.filter('formatDate', function (value: unknown) {
  if (value === undefined) {
    return '';
  }
  if (value instanceof Date) {
    return moment(value).format('DD.MM.YY');
  }

  return moment(value as Date).format('DD.MM.YY');
});

Vue.filter('formatNiceDate', function (value: unknown) {
  moment.locale('de');

  if (value === undefined) {
    return '';
  }
  if (value instanceof Date) {
    return moment(value).format('dddd DD.MM.YY');
  }

  return moment(value as Date).format('dddd DD.MM.YY');
});

Vue.filter('formatNiceCourseType', function (value: unknown) {
  return getCourseTypeString(value as CourseType);
});

Vue.filter('formatNiceDayOfWeek', function (value: unknown) {
  return getDayOfWeekString(value as DayOfWeek);
});

Vue.filter('formatNiceEmailTemplateType', function (value: unknown) {
  return getEmailTemplateNameString(value as EmailTemplateType);
});

Vue.filter('formatTimeOfDate', function (value: unknown) {
  if (value === undefined) {
    return '';
  }

  return moment(value as Date).format('HH:mm');
});

Vue.filter('formatTime', function (value: unknown) {
  if (value === undefined) {
    return '';
  }
  if (moment.isDuration(value)) {
    return value.format('hh:mm');
  }

  return '';
});

import Vue2Filters from 'vue2-filters';
import { CourseType, getCourseTypeString } from './api/model/CourseType';
import { EmailTemplateType, getEmailTemplateNameString } from './api/model/EmailTemplateType';
import { EmailTemplate } from './api/model/EmailTemplate';
import { DayOfWeek, getDayOfWeekString } from './api/model/DayOfWeek';

Vue.use(Vue2Filters);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
