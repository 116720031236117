
import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { API } from '../api/service/APIAccess';
import Vue2Filters from 'vue2-filters';
import promiseDispatcher from '@/store/modules/promiseDispatcher';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import Messages from '@/store/modules/alert';
import { PresentableCourse } from '@/api/model/PresentableCourse';
import moment from 'moment';

@Component({
  name: 'BookableCoursesView',
  components: {
    ConfirmDialog,
  },
  mixins: [Vue2Filters.mixin],
  filters: {},
})
export default class BookableCoursesView extends Vue {
  $refs!: {
    confirmDialog: ConfirmDialog;
  };

  public selectMonthOpen = false;
  public monthToShow = '';
  public activateMonthSelector = null;

  public search = '';
  public courses: PresentableCourse[] = [];

  public get headers(): unknown {
    return [
      { text: 'Datum', align: 'start', value: 'startDate' },
      { text: 'Uhrzeit', align: 'start', value: 'startTime' },
      { text: 'Typ', align: 'start', value: 'courseType' },

      { text: 'Einheiten', align: 'start', value: 'niceUnitCount' },
      { text: 'Preis', align: 'start', value: 'totalPrice' },
      { text: 'Teilnehmer', align: 'start', value: 'participantsCount' },

      { text: 'Intensiv', value: 'isIntensive', align: 'start' },
      { text: 'Buchbar', value: 'isActive', align: 'start' },
    ];
  }

  private async mounted(): Promise<void> {
    this.monthToShow = moment(Date.now()).format('YYYY-MM');
    await promiseDispatcher.queueForegroundTask(this.loadCoursesInternal);
  }

  private async syncCourses(): Promise<void> {
    await promiseDispatcher.queueForegroundTask(this.syncCoursesInternal);
  }

  private async syncCoursesInternal(): Promise<void> {
    const first = moment(Date.parse(this.monthToShow + '-01'));
    const last = moment(Date.parse(this.monthToShow + '-01')).endOf('month');

    await this.loadCoursesInternal();
  }

  private async loadCoursesInternal(): Promise<void> {
    const first = moment(Date.parse(this.monthToShow + '-01'));
    const last = moment(Date.parse(this.monthToShow + '-01')).endOf('month');

    const allCourses = await API.getPresentableCourses(
      first.format('YYYY-MM-DD'),
      last.format('YYYY-MM-DD')
    );

    this.courses = _(allCourses).orderBy(['startDate', 'startTime'], ['asc', 'asc']).value();
  }
}
