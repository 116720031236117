export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

const DayOfWeekName = new Map<DayOfWeek, string>([
  [DayOfWeek.Sunday, 'Sonntag'],
  [DayOfWeek.Monday, 'Montag'],
  [DayOfWeek.Tuesday, 'Dienstag'],
  [DayOfWeek.Wednesday, 'Mittwoch'],
  [DayOfWeek.Thursday, 'Donnerstag'],
  [DayOfWeek.Friday, 'Freitag'],
  [DayOfWeek.Saturday, 'Samstag'],
]);

export function getDayOfWeekString(e: DayOfWeek): string {
  let name = DayOfWeekName.get(e);
  if (!name) name = DayOfWeekName.get(DayOfWeek[e]);
  if (!name) return 'Nicht definiert.';

  return name;
}
